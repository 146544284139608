
import { defineComponent, ref, PropType, computed } from 'vue'
import { UsersItem } from '@/store/settings/types'
import { useCancelToken } from '@/hooks/useCancelToken'
import { USER_SETTINGS_ACTION_TYPES } from '@/store/settings/actions'
import UpdateUserModal from '@/components/particles/SettingsLayout/UpdateUserModal.vue'
import useStore from '@/store'
import { PersonalInfo } from '@/store/settings/types'
import { createToast } from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css'

export default defineComponent({
  name: 'UsersTable',
  components: { UpdateUserModal },
  props: {
    tableInfo: {
      type: Array as PropType<UsersItem[]>,
      default: () => [],
    },
    headers: {
      type: Array,
      default: () => [],
    },
    numberOfVisibleRows: {
      type: Number,
      default: 10,
    },
  },
  emits: ['onGetUsers',
    'update:userData',],
  setup(props, { emit }) {
    const store = useStore()
    const isUpdateUserModalActive = ref(false)
    const userData = ref()
    const rowToChange = ref()
    const getUsersCancelToken = useCancelToken()

    const isError = computed(() => {
      if (store.state.userSettings.isError) {
        createToast({
          title: 'Erreur '+store.state.userSettings.error.status,
          description: store.state.userSettings.error.message
        },
          {
            type: 'danger',
            position: 'bottom-right',
            showIcon: true,
          })
         store.dispatch(
          USER_SETTINGS_ACTION_TYPES.RESET_ERROR_STATE,
          getUsersCancelToken.value
        )
      }
      return store.state.userSettings.isError
    })
    const showModalUpdate = (row: PersonalInfo) => {
      isUpdateUserModalActive.value = true
      rowToChange.value = row
      userData.value = row
    }

    const onPersonalInfoUpdate = async (updatedUserInfo: PersonalInfo) => {
      await store.dispatch(
        USER_SETTINGS_ACTION_TYPES.UPDATE_PERSONAL_INFO,
        updatedUserInfo
      )
      createToast("L'utilisateur a été modifié",
        {
          position: 'bottom-right',
          type: 'success',
          transition: 'bounce',
        })
      emit("onGetUsers")
    }



    return {
      isUpdateUserModalActive,
      showModalUpdate,
      onPersonalInfoUpdate,
      userData,
      isError
    }
  },
})
