
import { defineComponent, ref, computed } from 'vue'
import { usersTableHeaders } from '@/helpers/tableHeaders'
import { useCancelToken } from '@/hooks/useCancelToken'
import useStore from '@/store'
import { USER_SETTINGS_ACTION_TYPES } from '@/store/settings/actions'
import { UsersItem } from '@/store/settings/types'
import UsersTable from '@/components/particles/SettingsLayout/UsersTable.vue'
import LoadingSpinner from '@/components/particles/common/LoadingSpinner.vue'
import AddUserModal from '@/components/particles/SettingsLayout/AddUserModal.vue'
import { createToast } from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css'

export default defineComponent({
  name: 'UsersSettingsView',
  components: { UsersTable, LoadingSpinner, AddUserModal },
  setup() {
    const perPage = ref()
    const getUsersCancelToken = useCancelToken()
    const store = useStore()
    const isAddUserModalActive = ref(false)
    const isError = computed(() => {
      if (store.state.userSettings.isError) {
        createToast({
          title: 'Erreur '+store.state.userSettings.error.status,
          description: store.state.userSettings.error.message
        },
          {
            type: 'danger',
            position: 'bottom-right',
            showIcon: true,
          })
         store.dispatch(
          USER_SETTINGS_ACTION_TYPES.RESET_ERROR_STATE,
          getUsersCancelToken.value
        )
      }
      return store.state.userSettings.isError
    })
    const getUsersInfo = async () => {
      await store.dispatch(
        USER_SETTINGS_ACTION_TYPES.GET_USERS,
        getUsersCancelToken.value
      )
    }
    getUsersInfo()

    const usersList = computed(() => store.state.userSettings.users)
    const isLoaded = computed(() => store.state.userSettings.isLoaded)



    const addNewUser = async (newUser: UsersItem) => {
      await store.dispatch(USER_SETTINGS_ACTION_TYPES.ADD_USER, newUser)
      getUsersInfo()
      createToast("L'utilisateur a été ajouté",
        {
          position: 'bottom-right',
          type: 'success',
          transition: 'bounce',
        })
    }




    return {
      perPage,
      usersTableHeaders,
      usersList,
      isLoaded,
      isAddUserModalActive,
      addNewUser,
      getUsersInfo,
      isError
    }
  },
})
