import { resolveComponent as _resolveComponent, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-full" }
const _hoisted_2 = { class: "flex justify-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoadingSpinner = _resolveComponent("LoadingSpinner")!
  const _component_UiIcon = _resolveComponent("UiIcon")!
  const _component_UiButton = _resolveComponent("UiButton")!
  const _component_UsersTable = _resolveComponent("UsersTable")!
  const _component_AddUserModal = _resolveComponent("AddUserModal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createVNode(_component_LoadingSpinner, { class: "h-screen" }, null, 512), [
      [_vShow, _ctx.isLoaded]
    ]),
    _withDirectives(_createElementVNode("div", null, null, 512), [
      [_vShow, _ctx.isError]
    ]),
    _createElementVNode("div", null, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_UiButton, {
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isAddUserModalActive = !_ctx.isAddUserModalActive)),
          class: "mb-4 p-2.5 bg-blue text-white font-medium"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_UiIcon, {
              class: "mr-2.5",
              name: "plus"
            }),
            _createTextVNode(" " + _toDisplayString(_ctx.$t('SETTINGS_USERS_DROPDOWN_BUTTON')), 1)
          ]),
          _: 1
        })
      ]),
      _createVNode(_component_UsersTable, {
        headers: _ctx.usersTableHeaders,
        loading: false,
        tableInfo: _ctx.usersList,
        numberOfVisibleRows: _ctx.perPage,
        "onUpdate:numberOfVisibleRows": _cache[1] || (_cache[1] = ($event: any) => (_ctx.perPage = $event)),
        onOnGetUsers: _ctx.getUsersInfo
      }, null, 8, ["headers", "tableInfo", "numberOfVisibleRows", "onOnGetUsers"]),
      _createVNode(_component_AddUserModal, {
        "model-value": _ctx.isAddUserModalActive,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.isAddUserModalActive = $event)),
        onOnAddUser: _ctx.addNewUser
      }, null, 8, ["model-value", "onOnAddUser"])
    ])
  ]))
}