import { vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UiIcon = _resolveComponent("UiIcon")!
  const _component_UiButton = _resolveComponent("UiButton")!
  const _component_UiTable = _resolveComponent("UiTable")!
  const _component_UpdateUserModal = _resolveComponent("UpdateUserModal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _withDirectives(_createElementVNode("div", null, null, 512), [
      [_vShow, _ctx.isError]
    ]),
    _createVNode(_component_UiTable, {
      headers: _ctx.headers,
      data: _ctx.tableInfo,
      "per-page": _ctx.numberOfVisibleRows
    }, {
      "cell:details": _withCtx(({ row }) => [
        _createVNode(_component_UiButton, {
          icon: "",
          onClick: ($event: any) => (_ctx.showModalUpdate(row))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_UiIcon, { name: "edit" })
          ]),
          _: 2
        }, 1032, ["onClick"])
      ]),
      _: 1
    }, 8, ["headers", "data", "per-page"]),
    _createVNode(_component_UpdateUserModal, {
      userData: _ctx.userData,
      "onUpdate:userData": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.userData) = $event)),
      "model-value": _ctx.isUpdateUserModalActive,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.isUpdateUserModalActive = $event)),
      onOnUpdateUser: _ctx.onPersonalInfoUpdate,
      onOnSettingsFormSubmit: _ctx.onPersonalInfoUpdate
    }, null, 8, ["userData", "model-value", "onOnUpdateUser", "onOnSettingsFormSubmit"])
  ], 64))
}