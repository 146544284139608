
import { defineComponent } from 'vue'
import MySettingsForm from '@/components/particles/forms/SettingsForm/MySettingsForm.vue'


export default defineComponent({
  name: 'AddUserModal',
  components: { MySettingsForm },
  emits: ['update:model-value', 'onAddUser'],
  setup(props, { emit }) {
    const onAddUser = (newUser: any) => {
      emit('onAddUser', newUser)
      emit('update:model-value', false)
    }
    return { onAddUser }
  },
})
