import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "py-8 bg-lightGrey" }
const _hoisted_2 = { class: "mb-4 text-black text-sm font-medium" }
const _hoisted_3 = { class: "max-w-md mx-auto text-darkGrey text-base text-center" }
const _hoisted_4 = { class: "p-5" }
const _hoisted_5 = { class: "flex space-x-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UiButton = _resolveComponent("UiButton")!
  const _component_MySettingsForm = _resolveComponent("MySettingsForm")!
  const _component_UiModal = _resolveComponent("UiModal")!

  return (_openBlock(), _createBlock(_component_UiModal, { "wrapper-class": "max-w-2xl w-full rounded-lg overflow-hidden" }, {
    title: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$t('SETTINGS_USERS_DROPDOWN_BUTTON')), 1),
        _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t('SETTINGS_USERS_MODAL_DESCRIPTION')), 1)
      ])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_MySettingsForm, {
          asteriskOnRequired: true,
          onOnSettingsFormSubmit: _ctx.onAddUser,
          isAdding: true
        }, {
          buttons: _withCtx(({ isSubmitting, isValid }) => [
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_UiButton, {
                class: "py-3 bg-lightGrey text-darkGrey w-full text-sm hover:text-red",
                type: "button",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:model-value', false)))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('DASHBOARD_TABLE_POP_UP_ADD_OS_CANCEL')), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_UiButton, {
                class: "w-full py-3 bg-blue hover:bg-darkBlue text-white",
                loading: isSubmitting,
                type: "submit",
                disabled: !isValid
              }, {
                default: _withCtx(() => [
                  _createElementVNode("p", null, _toDisplayString(_ctx.$t('DASHBOARD_TABLE_POP_UP_ADD_OS_CONFIRM')), 1)
                ]),
                _: 2
              }, 1032, ["loading", "disabled"])
            ])
          ]),
          _: 1
        }, 8, ["onOnSettingsFormSubmit"])
      ])
    ]),
    _: 1
  }))
}