
import { defineComponent, PropType } from 'vue'
import MySettingsForm from '@/components/particles/forms/SettingsForm/MySettingsForm.vue'
import { PersonalInfo } from '@/store/settings/types'

export default defineComponent({
  name: 'UpdateUserModal',
  props: {
    userData: {
      type: Object as PropType<PersonalInfo>,
      default: () => ({}),
    },
  },
  components: { MySettingsForm },
  emits: ['update:model-value', 'onUpdateUser'],
  setup(props, { emit }) {
    const onPersonalInfoUpdate = (updateUser: any) => {
      emit('onUpdateUser', updateUser)
      emit('update:model-value', false)
    }
    return { onPersonalInfoUpdate }
  },
})
